// import { ApolloProvider } from '@apollo/react-hooks'
// import { InMemoryCache } from 'apollo-cache-inmemory'
// import ApolloClient from 'apollo-client'
// import { split } from 'apollo-link'
// import { HttpLink } from 'apollo-link-http'
// import { WebSocketLink } from 'apollo-link-ws'
// import { getMainDefinition } from 'apollo-utilities'
import React from 'react'
import { Header, Match, Root } from '../components'
import { WpContextProvider } from '../contexts'
import { getJsonFromParams } from '../helpers'
import { Page, Tournament as ITournament } from '../lib/createPages'

interface MatchTemplateProps {
  pageContext: { tournament: ITournament; edges: { node: Page }[] }
  location: Location
}

// const httpLink = new HttpLink({
//   uri: "http://localhost:4000/graphql", // use https for secure endpoint
// });

// // Create a WebSocket link:
// const wsLink = new WebSocketLink({
//   uri: "ws://localhost:4000/graphql", // use wss for a secure endpoint
//   options: {
//     reconnect: true
//   }
// });

// // using the ability to split links, you can send data to each link
// // depending on what kind of operation is being sent
// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const { kind, operation } = getMainDefinition(query);
//     return kind === 'OperationDefinition' && operation === 'subscription';
//   },
//   wsLink,
//   httpLink,
// );

// // Instantiate client
// const client = new ApolloClient({
//   link,
//   cache: new InMemoryCache()
// })

const MatchTemplate: React.FC<MatchTemplateProps> = ({ pageContext /* , location */ }) => {
  const params = typeof window !== 'undefined' ? getJsonFromParams<{ match: string }>(location.search.substr(1)) : {}

  return (
    <WpContextProvider tournament={pageContext.tournament}>
      <Root pageContext={pageContext}>
        {/* <ApolloProvider client={client}> */}
          <Header pageContext={pageContext}></Header>
          <Match matchId={+(params.match || 0)} />
        {/* </ApolloProvider> */}
      </Root>
    </WpContextProvider>
  )
}

export default MatchTemplate
